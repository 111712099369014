import React, { useState } from "react";
import axios from "axios";
import { api } from "./services/Api";

const Canvas = (props) => {
  const [value, setValue] = useState({
    title: "",
    content: "",
    description: "",
  });

  const [content, setContent] = useState("");

  console.log("value", value);
  console.log("content", content);

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const createStory = () => {
    let data = {};

    data["title"] = value.title;
    data["content"] = value.content;
    data["description"] = value.description;

    api("post", `/api/story/new`, data)
      .then((res) => {
        console.log("asd", res);
        if (res.status && res.status == 200) {
          setValue({
            title: "",
            content: "",
            description: "",
          });

          props.displayToast("success", "Success create story");
        } else {
          if (res.response && res.response.status != 200)
            props.displayToast("error", "Error create story");
        }
        setContent("");
      })
      .catch((err) => {
        props.displayToast("error", "Error create story");
      });
  };

  return (
    <div className="h-full flex flex-col ">
      <div className="h-1/5">
        <div className="flex justify-between">
          <h3 className="text-2xl font-bold">Create new Story</h3>\
          <div className="flex justify-end ">
            <button
              onClick={createStory}
              className="bg-gray-700 py-2 px-3 rounded-lg text-white font-bold hover:bg-gray-800 cursor-pointer"
            >
              Create Story
            </button>
          </div>
        </div>

        <input
          className="p-2 shadow border border-gray-300 my-3 w-96 text-sm font-bold"
          placeholder="Title"
          name="title"
          value={value.title}
          onChange={handleChange}
        ></input>
        <br />
        <input
          className="p-2 shadow border border-gray-300 mb-3 w-96 text-sm "
          placeholder="Description"
          name="description"
          value={value.description}
          onChange={handleChange}
        ></input>
      </div>
      <div className="h-full">
        <textarea
          theme="snow"
          value={value.content}
          onChange={handleChange}
          className="w-full h-full border px-3 py-2 text-sm shadow-lg"
          name="content"
          placeholder="Once upon a time ..."
        />
      </div>
    </div>
  );
};

export default Canvas;
