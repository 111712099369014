import React, { useEffect, useState } from "react";
import { api } from "../services/Api";
import { Link } from "react-router-dom";
import DeleteStoryModal from "./DeleteStory";
import { TiEdit, TiEye, TiPencil } from "react-icons/ti";

const Index = (props) => {
  const [story, setStory] = useState([])

  const fetchOwnBook = () => {
    api('get', '/api/story/own').then((res) => {
        console.log("res", res);
        setStory(res.data)
    }).catch((err) => {
        console.log(err);
    })
  }

  useEffect(() => {
    fetchOwnBook()
  }, [])

  return (
    <>  
    <div className="h-full overflow-scroll ">

      {story ? story.map((data) => (
        <div className=" shadow-sm px-3 py-2 rounded-md m-2 bg-gray-100 flex justify-between">
            <div className="m-2">
          <strong className="text-lg">{data.title}</strong>
          <p>{data.description}</p>
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-2">
                <Link to={`/story/${data.id}`}>
                <button className="bg-gray-700 text-white px-3 py-1.5 rounded-md hover:bg-gray-600"><TiEye className="text-xl "/></button>
                </Link>
                <button className="bg-yellow-500 hover:bg-yellow-600 px-3 py-1.5 rounded-md"><TiEdit className="text-xl text-white"/></button>
                <DeleteStoryModal id={data.id} fetchBook={fetchOwnBook} displayToast={props.displayToast}/>
            </div>
        </div>
      )) : ''}
      </div>
    </>
  );
};

export default Index;
