import React, { useState } from "react";
import { TiUser } from "react-icons/ti";
import axios from "axios";
import { SuccessToast } from "../components/Toast";

const SignIn = (props) => {
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [successToast, setSuccessToast] = useState(false);

  const signIn = async () => {
    await axios.get(`${process.env.REACT_APP_API_HOSTNAME}/sanctum/csrf-cookie`).then((res) => {
      console.log("res", res);
      axios.post(`${process.env.REACT_APP_API_HOSTNAME}/api/signin`, {
        email: input.email,
        password: input.password,
      }).then((res) => {
          console.log("kambing ", res);
          if(res.status == 200){
            props.displayToast('success', 'Success Login');
            setTimeout(() => {
              window.location.reload();
              localStorage.setItem('user', JSON.stringify(res.data))
            }, 1000)
          } 
      }).catch((err) => {
        props.displayToast('error', 'Error Login');
  
      });
    });
  };

  const handleChange = (e) => {
    setInput({
        ...input,
        [e.target.name]: e.target.value
    })
  }
  return (
    <>
      <div className="border border-red-400 w-full h-screen flex justify-center items-center">
        <div className="w-1/4 flex flex-col items-center gap-y-10">
          {/* <h3 className="font-bold text-lg">Cert Makerz</h3> */}
          <div className="flex flex-col gap-y-4">
            <input
              type="email"
              name="email"
              placeholder="email"
              className="py-2 px-3 border rounded-md"
              onChange={(e) => handleChange(e)}
            ></input>
            <input
              type="password"
              name="password"
              placeholder="password"
              className="py-2 px-3 border rounded-md"
              onChange={(e) => handleChange(e)}
            ></input>
            <button
              className="font-semibold py-2 px-3 bg-gray-700 text-white flex justify-center items-center gap-x-3 rounded-md
                        hover:bg-gray-600 mt-6"
              onClick={() => signIn()}
            >
              <TiUser className="text-xl" />
              Sign in
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
