import SidebarLayout from "../Sidebar"
import BottomBarLayout from "../bottomBar"

const Index403 = (props) => {
    return (
        <>   
            <div className="w-full h-full flex flex-col gap-2 justify-center items-center">
                <h5 className="text-2xl sm:text-4xl font-bold">403</h5>
                <p className="sm:text-lg text-center">You need to sign in to access this feature.</p>
                <button className="bg-gray-800 text-white px-4 py-2 rounded-md mt-4">Sign in</button>
            </div>
  
        </>
    )
}

export default Index403