import React, { useEffect, useState } from "react";
import Canvas from "./Canvas";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import SidebarLayout from "./Sidebar";
import StoryList from "./StoryList";
import StoryIndex from "./story/Index";
import SignIn from "./auth/SignIn";
import Show from "./story/Show";
import { SuccessToast } from "./components/Toast";
import BottomBarLayout from "./bottomBar";
import Index403 from "./auth/403";

const App = () => {
  const isAuthenticated = localStorage.getItem("user");
  const [showToast, setShowToast] = useState(false);
  const [toastAttr, setToastAttr] = useState({
    status: "",
    msg: "",
  });
  const location = useLocation();

  const displayToast = (status, msg) => {
    setShowToast(true);
    setToastAttr({
      status: status,
      msg: msg,
    });
    setTimeout(() => {
      setShowToast(false);
      setToastAttr({
        status: "",
        msg: "",
      });
    }, 2000);
  };

  useEffect(() => {}, [isAuthenticated]);

  const toastComponent = (data) => {
    console.log("data", data);
    return <SuccessToast status={toastAttr.status} msg={toastAttr.msg} />;
  };

  return (
    <>
      {isAuthenticated ? (
        <div className="w-full h-screen p-0 flex justify-center overflow-hidden">
          <div className="flex flex-col md:flex-row  w-full p-3 ">
            <div className="mr-2 hidden md:block">
              <SidebarLayout displayToast={displayToast} />
            </div>

            <div className="w-full h-full md:h-full ">
              <Routes>
                <Route
                  exact
                  path="*"
                  element={<Navigate to={"/create"} replace={true} />}
                />
                <Route
                  path="/create"
                  element={<Canvas displayToast={displayToast} />}
                />
                <Route default path="/discovery" element={<StoryList />} />
                <Route
                  default
                  path="/story"
                  element={<StoryIndex displayToast={displayToast} />}
                ></Route>
                <Route path="/story/:id" element={<Show />}></Route>
              </Routes>
            </div>
            <div className="mr-2 md:hidden absolute left-0 bottom-0 fixed w-full px-2">
              <BottomBarLayout displayToast={displayToast} />
            </div>
          </div>
        </div>
      ) : (
        <>
          {location.pathname != "/sign-in" ? (
            <div className="w-full h-screen p-0 flex justify-center overflow-hidden">
              <div className="flex flex-col md:flex-row  w-full p-3 ">
                <div className="mr-2 hidden md:block">
                  <SidebarLayout displayToast={displayToast} />
                </div>

                <div className="w-full h-full md:h-full ">
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={<Navigate to={"/discovery"} replace={true} />}
                    />
                    <Route
                      exact
                      path="/create"
                      element={<Navigate to={"/unauthorized"} replace={true} />}
                    />
                    <Route
                      exact
                      path="/story"
                      element={<Navigate to={"/unauthorized"} replace={true} />}
                    />
                    <Route default path="/discovery" element={<StoryList />} />
                    <Route path="/story/:id" element={<Show />}></Route>
                    <Route
                      path="/unauthorized"
                      element={<Index403 displayToast={displayToast} />}
                    />
                  </Routes>
                </div>
                <div className="mr-2 md:hidden absolute left-0 bottom-0 fixed w-full px-2">
                  <BottomBarLayout displayToast={displayToast} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Routes>
            <Route
              path="/sign-in"
              element={<SignIn displayToast={displayToast} />}
            />
          </Routes>
        </>
      )}
      {showToast ? toastComponent() : ""}
    </>
  );
};

export default App;
