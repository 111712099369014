import axios from "axios"

const session = JSON.parse(localStorage.getItem('user'))

const config = {
    headers:{
        "Authorization": `Bearer ${session ? session.data.token : ''}`,
        // "Access-Control-Allow-Origin": `*`,
        // "Access-Control-Allow-Credentials": true,
        // "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept',
        "Accept": 'application/json',
        "X-Requested-With": 'XMLHttpRequest'
      }
}

axios.defaults.withCredentials = true

export const api = (method, url, data) => {
    switch(method){
        case 'get':
           return axios.get(`${process.env.REACT_APP_API_HOSTNAME}` + url, config).then((res) => {return res}).catch((err) => { return err})
        case 'post':
            return axios.post(`${process.env.REACT_APP_API_HOSTNAME}` + url, data, config).then((res) => {return res}).catch((err) => { return err})
            default:
    }
}
