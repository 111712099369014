import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  TiArrowForward,
  TiArrowLeft,
  TiArrowRight,
  TiArrowRightThick,
  TiBook,
  TiDocumentAdd,
  TiThLargeOutline,
  TiThList,
  TiUser,
  TiUserAdd,
} from "react-icons/ti";
import { api } from "./services/Api";
// import { TiDocumentAdd } from './../node_modules/react-icons/ti/index.esm';

const BottomBarLayout = (props) => {
  const location = useLocation();
  const auth = JSON.parse(localStorage.getItem("user"));
  const [isOpen, setIsOpen] = useState(false);

  const signOut = () => {
    api("post", "/api/signout")
      .then((res) => {
        console.log("kambing ", res);
        if (res.status && res.status == 200) {
          props.displayToast("success", "Success logout");
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
          }, 1000);
        } else {
          // if (res.response && res.response.status != 200) {
          props.displayToast("error", "Logout error");
          // }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const route = [
    {
      url: "/discovery",
      icon: <TiThLargeOutline className="text-2xl" />,
      title: "Discovery",
    },
    {
      url: "/create",
      icon: <TiDocumentAdd className="text-2xl" />,
      title: "Create",
    },
    // {
    //   url: "/contribute",
    //   icon: <TiPencil className="text-2xl" />,
    //   title: "Contribute",
    // },
    {
      url: "/story",
      icon: <TiBook className="text-2xl" />,
      title: "My Story",
    },
  ];

  const toggleBottomBar = () => {
    setIsOpen((prev) => !prev);
  };

  console.log("asd", isOpen);

  return (
    <>
      {!isOpen ? (
        <div
          className="bg-gray-300 text-gray-600 w-fit p-5 rounded-full my-2"
          onClick={() => toggleBottomBar()}
        >
          <TiThList className="text-xl" />
        </div>
      ) : (
        <div className="w-full">
          <aside
            id="default-sidebar"
            class=" transition-width duration-150 ease-in-out my-2"
            aria-label="Sidebar"
          >
            <div class="w-full py-2 bg-gray-200 rounded-lg ">
              <div class="flex flex-row justify-evenly items-center">
                <div className="">
                  {/* <Link to={""}> */}
                  <a
                    class="h-full text-gray-700 flex justify-center items-center p-2 text-center rounded-lg  border "
                    onClick={() => toggleBottomBar()}
                  >
                    <span class="ml-3 ">
                      <TiThList className="text-xl" />
                    </span>
                  </a>

                  {/* </Link> */}
                </div>
                {route.map((data) => (
                  <>
                    <div className="">
                      <Link to={data.url}>
                        {location.pathname == data.url ? (
                          <a class="flex justify-center items-center p-2 text-base font-semibold text-gray-900 rounded-lg  bg-gray-100 ">
                            {data.icon}
                          </a>
                        ) : (
                          <a class="flex justify-center items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                            {data.icon}
                          </a>
                        )}
                      </Link>
                    </div>
                  </>
                ))}
                  {auth ? (
                      <a
                        class="text-black flex justify-center items-center p-2 text-base rounded-lg  bg-gray-300 hover:bg-gray-400 cursor-pointer "
                        onClick={() => signOut()}
                      >
                        <TiArrowRight className="text-2xl" />
                      </a>
                  ) : (
                      <Link to={"/sign-in"}>
                        <a class="text-white flex justify-center items-center p-2 text-base rounded-lg bg-gray-700 hover:bg-gray-800 cursor-pointer ">
                          <TiUserAdd />
                        </a>
                      </Link>
                  )}
              </div>
            </div>
          </aside>
        </div>
      )}
    </>
  );
};

export default BottomBarLayout;
