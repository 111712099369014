import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  TiArrowRight,
  TiBook,
  TiDocumentAdd,
  TiThLargeOutline,
  TiUser,
} from "react-icons/ti";
import { api } from "./services/Api";
// import { TiDocumentAdd } from './../node_modules/react-icons/ti/index.esm';

const SidebarLayout = (props) => {
  const location = useLocation();
  const auth = JSON.parse(localStorage.getItem("user"));

  const signOut = () => {
    api("post", "/api/signout")
      .then((res) => {
        console.log("kambing ", res);
        if (res.status && res.status == 200) {
          props.displayToast("success", "Success logout");
          setTimeout(() => {
            localStorage.clear();
            window.location.href = '/';
          }, 1000);
        } else {
          // if (res.response && res.response.status != 200) {
          props.displayToast("error", "Logout error");
          // }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const route = [
    {
      url: "/create",
      icon: <TiDocumentAdd className="text-2xl" />,
      title: "Create",
    },
    {
      url: "/discovery",
      icon: <TiThLargeOutline className="text-2xl" />,
      title: "Discovery",
    },
    // {
    //   url: "/contribute",
    //   icon: <TiPencil className="text-2xl" />,
    //   title: "Contribute",
    // },
    {
      url: "/story",
      icon: <TiBook className="text-2xl" />,
      title: "My Story",
    },
  ];

  return (
    <>
      <aside
        id="default-sidebar"
        class="w-20 xl:w-52 transition-width duration-150 ease-in-out h-full max-h-screen mr-1"
        aria-label="Sidebar"
      >
        <div class="h-full px-2 py-3 overflow-y-auto bg-gray-200 rounded-lg flex flex-col justify-between">
          <ul class="space-y-2">
            {route.map((data) => (
              <>
                <li>
                  <Link to={data.url}>
                    {location.pathname == data.url ? (
                      <a class="flex justify-center items-center p-2 text-base font-semibold text-gray-900 rounded-lg  bg-gray-100 ">
                        {data.icon}
                        <span class=" hidden xl:ml-3  xl:block">
                          {data.title}
                        </span>
                      </a>
                    ) : (
                      <a class="flex justify-center items-center p-2 text-base font-normal text-gray-900 rounded-lg  hover:bg-gray-100 ">
                        {data.icon}
                        <span class="xl:ml-3 hidden xl:block">
                          {data.title}
                        </span>
                      </a>
                    )}
                  </Link>
                </li>
              </>
            ))}
          </ul>
          <ul>
            <li>
              {/* <Link to={""}> */}
              <a class="flex justify-center items-center p-2 text-base rounded-lg  bg-gray-200 mb-2">
                <TiUser className="text-2xl" />
                <span class="ml-3 hidden xl:block">
                  {auth && auth.data.user ? auth.data.user.name : "Guess"}
                </span>
              </a>

              {/* </Link> */}
            </li>
            {auth ? (
              <li>
                {/* <Link to={""}> */}
                <a
                  class="text-black flex justify-center items-center p-2 text-base rounded-lg  bg-gray-300 hover:bg-gray-400 cursor-pointer "
                  onClick={() => signOut()}
                >
                  <TiArrowRight className="text-2xl" />
                  <span class="ml-3 hidden xl:block">Logout</span>
                </a>

                {/* </Link> */}
              </li>
            ) : (
              <li>
                <Link to={"/sign-in"}>
                <a
                  class="text-white flex justify-center items-center p-2 text-base rounded-lg bg-gray-700 hover:bg-gray-800 cursor-pointer "
                 
                >
                  <span class="ml-3 hidden xl:block">Sign in</span>
                </a>

                </Link>
              </li>
            )}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SidebarLayout;
