import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "../services/Api";

const Show = () => {
  const location = useLocation();
  const params = useParams();
  const [activeChapter, setActiveChapter] = useState(0);

  const [data, setData] = useState({
    book: {
        title: "",
        description: ""
    },
    content: []
  });

  console.log("data", data);

  const fetchStory = () => {
    api("get", `/api/story/show?id=${params.id}`)
      .then((res) => {
        console.log("res", res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeChapter = (id) => {
    setActiveChapter(id)
  }

  useEffect(() => {
    fetchStory();
  }, []);
  return (
    <>
      <div className="w-full h-full">
        <div className="h-1/4 bg-gray-100 p-3 rounded-md">
          <div className="bg-green-500 rounded-full w-fit py-0.5 px-4 font-semibold text-white my-2">
            {"Active"}
          </div>
          <div className=" flex flex-col gap-2 mt-3">
            <strong className="text-xl">{data ? data.book.title : ''}</strong>
            <p>{data ? data.book.description : ''}</p>
          </div>
        </div>
        <div className="h-3/4  py-2 flex flex-row">
          <div className="w-1/6  flex flex-col gap-y-2 my-2">
              {data.content.map((chapter, id) => (
                <>
                  <div className={`cursor-pointer py-2 px-3 text-white mx-2 rounded-md hover:bg-gray-600 ${activeChapter == id ? ' bg-gray-700' : 'bg-gray-500'}`} onClick={() => changeChapter(id)}>
                    {chapter.title}
                  </div>
                </>
              ))}
          </div>
          <div className="p-3 w-5/6 border h-full overflow-scroll rounded-md">
                {data.content[activeChapter] ? data.content[activeChapter].content.map((content) => (
                  <>
                  <p>{content.content}</p>
                  </>
                )): ''}
          </div>
            
        </div>
      </div>
    </>
  );
};

export default Show;
